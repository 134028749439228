// icons
import { earOutline, body, pulse, trendingDown, } from 'ionicons/icons';

// Camera streaming
import flvjs from 'flv.js';
import mpegts from 'mpegts.js';
import { useI18n } from 'vue-i18n';

const activeFlvPlayers = {}; // only 1 instance at the same time for each selector

export function utilsDevice() {
  const { t, locale } = useI18n();
  const tStr = (chi, eng) => (locale.value == 'zh' ? chi : eng);

  const formatVal = (val, unit, toFixedDigits = 0) => ((val || val == 0) ? `${val.toFixed(toFixedDigits)} ${unit}` : `-`);

  const getRotateDegreeFromAcc = (data, normalize = false) => {
    const { initAccX, initAccZ, accX, accZ } = (data || {}); // asset table: initAcc, latest_data: acc
    const checkAccX = initAccX != null ? initAccX : accX;
    const checkAccZ = initAccZ != null ? initAccZ : accZ;
    let degree = Math.round((Math.atan2(checkAccX, checkAccZ) * 180 / Math.PI) * 100) / 100;

    // Normalize to 0-90 range
    degree = Math.abs(degree);  // First make it positive
    
    // Convert to 0-90 range by using the complementary angle when needed
    if (degree > 90 && degree <= 180) {
        degree = 180 - degree;
    } else if (degree > 180 && degree <= 270) {
        degree = degree - 180;
    } else if (degree > 270) {
        degree = 360 - degree;
    }
    return degree;
  }
  const getAssetAngleDiff = (selectedAssetPart) => {
    const getValObj = (val, statusText, statusColor, code) => ({ val: formatVal(val, '°', 1), statusText, statusColor, code });
    const latestAngle = getRotateDegreeFromAcc(selectedAssetPart['latestAssetData']);
    const initAngle = getRotateDegreeFromAcc(selectedAssetPart); // standard / correct angle
    const angleDiff = latestAngle-initAngle, absAngleDiff = Math.abs(angleDiff);
    //if (isNaN(angleDiff)) return { val: '-', statusText: tStr('未知', 'Unknown'), statusColor: 'dark', code: 'unknown' }; // data not available yet
    if (isNaN(angleDiff)) return { val: '-', statusText: tStr('正常', 'Normal'), statusColor: 'success', code: 'normal' }; // data not available yet
    if (absAngleDiff <= 5) return getValObj(absAngleDiff, tStr('正常', 'Normal'), 'success', 'normal'); // normal (due to error / slight movement)
    //if (absAngleDiff <= 20) return getValObj(absAngleDiff, tStr('可能有問題', 'Suspect'), 'warning'); // a bit abnormal
    //return getValObj(absAngleDiff, tStr('有問題', 'Abnormal'), 'danger'); // abnormal (quite likely got cut off)
    if (absAngleDiff <= 15) return getValObj(absAngleDiff, tStr('4級警報', 'Mild'), 'p4', 'p4'); // a bit abnormal
    if (absAngleDiff <= 45) return getValObj(absAngleDiff, tStr('3級警報', 'Medium'), 'p3', 'p3'); // a bit abnormal
    if (absAngleDiff <= 60) return getValObj(absAngleDiff, tStr('2級警報', 'Severe'), 'p2', 'p2'); // a bit abnormal
    return getValObj(absAngleDiff, tStr('1級警報', 'Extreme'), 'p1', 'p1'); // abnormal (quite likely got cut off)
  }

  const pointEntityTypes = [
    { id: 'workPhotoRecord', name: '修葺位置', nameEn: 'Repair Location' },
    { id: 'edge', name: 'AI邊綠盒子', nameEn: 'AI Edge Computer' },
    { id: 'bcam', name: 'AI攝影機', nameEn: 'AI Camera' },
    { id: 'beacon', name: '藍牙定位信標', nameEn: 'BLE Beacon' },
    { id: 'helmet', name: '智能安全帽', nameEn: 'Smart Helmet' },
    { id: 'anchor', name: '連牆器', nameEn: 'Putlog', table: 'assets' },
    { id: 'batch-anchor', name: '連牆器 (批量產生)', nameEn: 'Putlogs (Batch)', table: 'assets' },
    { id: 'vss-gateway', name: '棚感器網關', nameEn: 'Scaffold Sensor Gateway' },
    //{ id: 'vss', name: '棚感器', nameEn: 'Scaffold Sensor' }, // TBC: allow adding devices via map?
  ];
  const getPointTypeObj = (id) => (pointEntityTypes.find(t => t.id == id) || {});

  // Icon Settings for alerts
  const alertIcons = {
    '暑熱警告': {
      'green': require('@/assets/icons/online.svg'),
      'yellow': require('@/assets/icons/heat_warning_yellow.jpeg'),
      'red': require('@/assets/icons/heat_warning_red.jpeg'),
      'black': require('@/assets/icons/heat_warning_black.jpeg'),
    },
    '噪音水平': {
      'low': earOutline,
      'medium': earOutline,
      'high': earOutline,
    },
    '人體偵測': {
      '1': body,
    },
    '震動警報': {
      'very_low': pulse,
      'low': pulse,
      'medium': pulse,
      'high': pulse,
    },
    '傾斜警報': {
      '1': trendingDown,
      '2': trendingDown,
      '3': trendingDown,
      '4': trendingDown,
    },
  }
  const getDeviceLogMsg = (log: any) => {
    const { level, prevLevel, type } = log;
    switch (type) {
      case '暑熱警告':
        if (level == 'green') return tStr(`所有暑熱警告取消`, `All Heat Stress at Work Warnings Cancelled`);
        if (!prevLevel || prevLevel == 'green') return tStr(`${t(level)}暑熱警告`, `Heat Stress at Work Warning (${t(level)})`);
        return tStr(`暑熱警告由${t(prevLevel)}改為${t(level)}`, `Heat Stress at Work Warning (${t(level)})`);
    
      case '人體偵測':
        return tStr(`偵測到有人存在`, `Human Presence Detected`);

      case '震動警報':
        if (level == 'low') return tStr(`偵測到輕微震動`, `Slight Vibration Detected`);
        if (level == 'medium') return tStr(`偵測到強烈震動`, `Medium Vibration Detected`);
        if (level == 'high') return tStr(`偵測到超強烈震動`, `Strong Vibration Detected`);
        return tStr(`偵測到震動`, `Vibration Detected`);

      case '傾斜警報':
        if (level == '1') return tStr(`4級警報 | 偵測到輕微傾斜`, `[P4] Slight Inclination Detected`);
        if (level == '2') return tStr(`3級警報 | 偵測到中等傾斜`, `[P3] Medium Inclination Detected`);
        if (level == '3') return tStr(`2級警報 | 偵測到嚴重傾斜`, `[P2] Severe Inclination Detected`);
        if (level == '4') return tStr(`1級警報 | 連牆器可能已分離/失效`, `[P1] Putlog may be cut off`);
        //if (level == '2') return tStr(`偵測到嚴重傾斜`, `Medium Inclination Detected`);
        //if (level == '3') return tStr(`偵測到超嚴重傾斜`, `Severe Inclination Detected`);
        return tStr(`偵測到傾斜`, `Inclination Detected`);

      case '噪音水平':
        return tStr(`噪音水平超標`, `Excessive Noise Level`);

      // case '空氣質素'
      // case '惡劣天氣'
        
      default:
        return type;
    }
  }

  const refreshCamStreaming = (device: any, edgeDevices, selector = '#video-stream-1') => {
    //if (flvjs.isSupported()) {
    if (mpegts.getFeatureList().mseLivePlayback) {
      const { vpnIp, gatewayId, chid, } = device;
      let url = vpnIp;
      if (gatewayId) { // AI Edge Channel
        const edge = edgeDevices.find(d => d.id == gatewayId);
        url = `${edge.vpnIp}/preview/${chid}.live.flv`;
      }
      if (url) {
        setTimeout(() => {
          const element: any = document.querySelector(selector);
          if (activeFlvPlayers[selector]) {
            activeFlvPlayers[selector].destroy(); // clean-up existing player first
          }
          const flvPlayer = mpegts.createPlayer({
            type: 'flv',
            isLive: true,
            url: url.startsWith("192") ? `https://iot.b-plus.tech/stream-camera?token=j412DSGT23JNSDF4LNBdfn135&ip=${url}` : url,
            hasAudio: false,
            cors: true
          }, {
            enableWorker: true,
            enableWorkerForMSE: true,
            enableStashBuffer: true,
            liveBufferLatencyChasing: true,  // Enable live buffer latency chasing
            liveBufferLatencyChasingOnPaused: true,
            liveSync: false,                  // Disable sync to prevent aggressive catching up
            liveBufferLatencyMaxLatency: 30,
            autoCleanupSourceBuffer: true,
            autoCleanupMaxBackwardDuration: 180,
            autoCleanupMinBackwardDuration: 120,
            lazyLoad: false,                  // Disable lazy loading to maintain continuous connection
            fixAudioTimestampGap: true
          });

          let retryCount = 0;
          const maxRetries = 3;
          let retryTimeout: any = null;

          const handleError = (err: any) => {
            console.error('Stream error:', err);
            if (retryCount < maxRetries) {
              retryCount++;
              console.log(`Retrying stream (${retryCount}/${maxRetries})...`);
              
              // Clear any existing retry timeout
              if (retryTimeout) {
                clearTimeout(retryTimeout);
              }

              // Attempt recovery
              retryTimeout = setTimeout(() => {
                if (activeFlvPlayers[selector]) {
                  flvPlayer.unload();
                  flvPlayer.destroy();
                  activeFlvPlayers[selector] = null;
                  // Recreate the player
                  refreshCamStreaming(device, edgeDevices, selector);
                }
              }, 6000 * retryCount); // Exponential backoff
            }
          };

          // Handle various error events
          flvPlayer.on('error', handleError);
          flvPlayer.on('loading_complete', () => { retryCount = 0; }); // Reset retry count on successful load
          flvPlayer.on('media_info', () => { retryCount = 0; }); // Reset retry count on successful media info
          
          // Handle media element errors
          element.addEventListener('error', handleError);

          flvPlayer.attachMediaElement(element);
          flvPlayer.load();
          activeFlvPlayers[selector] = flvPlayer;
        }, 1000);
      }
    }
  }

  return {
    tStr,
    formatVal,
    getRotateDegreeFromAcc,
    getAssetAngleDiff,

    alertIcons, getDeviceLogMsg,
    pointEntityTypes, getPointTypeObj,

    refreshCamStreaming,

    isDeviceOnline: (device: any) => {
      const { lastConnectTime } = device || {};
      const diff = Math.abs(new Date().getTime()-new Date(lastConnectTime).getTime());
      return Math.floor((diff/1000)/60) < 120; // Online if last seen within 2 hour
    },

    getAnchorAssetStatus: (assetParts) => {
      const getStatusObj = (text, color, code) => ({ text, color, code });
      // TBC: what if only 1 part has sensor installed?
      if (assetParts.every(p => !p.linkedDeviceId)) {
        return getStatusObj(tStr('未連接', 'Not connected'), 'medium', 'no-linked-device');
        //return getStatusObj(tStr('正常', 'Normal'), 'success', 'normal'); // TMP: for demo
      }
      let allUnknown = true;
      for (const part of assetParts) {
        const minTimestamp = new Date(new Date().getTime() - 12*3600*1000); // data should be at least 8 hours ago
        const { latestAssetData } = part;
        if (!latestAssetData || new Date(latestAssetData?.timestamp) < minTimestamp) {
          continue; // no data received (for the latest round of upload)
        }
        const statusObj = getAssetAngleDiff(part);
        if (statusObj.statusColor == 'success') {
          allUnknown = false;
        } else if (statusObj.statusColor != 'dark') { // skip if status unknown
          return getStatusObj(statusObj.statusText, statusObj.statusColor, statusObj.code);
          //if (statusObj.statusColor == 'danger') return getStatusObj(tStr('有問題', 'Abnormal'), 'danger', 'abnormal');
          //return getStatusObj(tStr('可能有問題', 'Suspect'), 'warning', 'suspect'); // some abnormal = whole anchor abnormal
        }
      }
      return allUnknown ? getStatusObj(tStr('沒有數據', 'No Data'), 'dark', 'unknown') : getStatusObj(tStr('正常', 'Normal'), 'success', 'normal'); // unknown / normal
    }
  }
}