import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';

export default {
  async updateUserLastAccessProjectTime(projectId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserLastAccessProjectTime')({ projectId })).data;
  },

  /**
   * BD report (work photo records)
   */
  async updateCheckLocationImage(projectId, id, step, photo = null, photoLink = null) {
    const newPhotoLink = photoLink || (photo ? (await uploadImage(photo.base64Data, `${id}-${step}`, 'ol')) : "");
    await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, step, newPhotoLink, projectId });
    return newPhotoLink;
  },
  async updateCheckLocationPointDamageArea(id, damageAreaLength, damageAreaWidth, damageType = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, damageAreaLength, damageAreaWidth, damageType, })).data;
  },
  async updateCheckLocationPointXY(id, pointX, pointY) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, pointX, pointY })).data;
  },
  async deleteCheckLocation(id) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')({ id, status: 'trashed' })).data;
  },
  async upsertCheckLocation({ projectId, id = null, pointX, pointY, workLocationId, targetFloor, photoCode, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId, }) {
    const payload = { projectId, id, pointX, pointY, workLocationId, targetFloor, photoCode, damageType, damageAreaLength, damageAreaWidth, ironState, subLocationId, };
    return (await firebase.app().functions('asia-east2').httpsCallable('project-upsertCheckLocation')(payload)).data;
  },

  // Batch operations
  async batchInsertPlotPoints(projectId, points, workLocationId, photoCodePrefix) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-batchInsertPlotPoints')({ projectId, points, workLocationId, photoCodePrefix })).data;
  },
  async batchDeletePoints(points) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-batchDeletePoints')({ points, })).data;
  },

  // READ
  async getAllProjects(customerId = 'all') { // Not in use (unless WINS allow publicly access)
    return (await firebase.app().functions('asia-east2').httpsCallable('project-getAllProjects')({ customerId })).data;
  },
  async getProjectById(projectId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('project-getProjectById')({ projectId })).data;
  },

  // UPDATE
  async subscribeProjectNotifications(projectId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('project-subscribeProjectNotifications')({ projectId });
  },
  async unsubscribeProjectNotifications(projectId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('project-unsubscribeProjectNotifications')({ projectId });
  }
}